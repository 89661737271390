<template>
  <FoldableCard :title="t['79']">
    <template #filterbody>
      <div class='p-fluid formgrid grid hr-line-dashed-bottom'>
        <div class="field col-12 md:col-3 mt-3">
              <span class="p-float-label">
                  <Dropdown id="languageInput" v-model="languageValue" :options="languageValueList" optionLabel="value" optionValue='key'/>
                  <label for="languageInput" class='font-bold'>{{ t['435'] }}</label>
              </span>
        </div>
      </div>
    </template>
    <template #filterfooter>
      <div class='mt-2'>
      </div>
    </template>
  </FoldableCard>

  <!-- TEXT LIST -->
  <div class='card'>
    <h4 class='font-bold hr-line-dashed-bottom'>Texts</h4>
    <DataTable :value="textList" :paginator='true' :rows='20' :rowsPerPageOptions='[10, 20, 50]' class='p-datatable-sm'
               :filters='filters'
               v-model:selection='selectedText' selectionMode='single' :loading='loading'>
      <div class='text-right mb-1'>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" :placeholder="t['82']" />
        </span>
      </div>
      <Column field="id" :header="t['460']" headerStyle='width: 10%'></Column>
      <Column field="text" :header="t['461']" headerStyle='width: 80%'>
      </Column>
      <Column headerStyle='width: 10%'>
        <template #body='slotProps'>
          <i class='pi pi-pencil' @click='openDialog(slotProps.data)'  style='font-size: 1rem'></i>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog v-model:visible='dialogVisible' :header="t['462']" :style="{width: '600px'}" :modal='true' :closable='false'>
    <div class='col-12 ml-0'>
      <div class='ml-2 mb-2'>
        <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-danger'/>
        <span class='font-light' style='font-size: 12px'>{{ '  ' + t['463'] }}</span>
        <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-warning ml-2'/>
        <span class='font-light' style='font-size: 12px'>{{ '  ' + t['464'] }}</span>
        <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-success ml-2'/>
        <span class='font-light' style='font-size: 12px'>{{ '  ' + t['465'] }}</span>
      </div>
      <div class='dashboard'>
        <div class='task-list'>
          <ul>
            <li v-for='text in dialogTextList' :key='text.id' style='border-bottom: none; padding: 0px 0px;'>
              <div class="p-inputgroup md:col-12">
                <span class="p-inputgroup-addon" style='list-style-type: none;'>{{text.langCode}}</span>
                <Textarea placeholder="Website" v-model='text.text' style='width: 100%' :autoResize='true'/>
                <Button v-bind:class="[text.status === 1 ? 'p-button-danger' : text.status === 2 ? 'p-button-warning' : 'p-button-success']" @click='text.status = ((text.status)%3)+1'></Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="t['86']" icon="pi pi-times" class='p-button-outlined' @click="dialogVisible=false"/>
      <Button :label="t['87']" icon="pi pi-check" class='p-button-outlined' @click="saveText"/>
    </template>
  </Dialog>

</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'Texts',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
      defLang: computed(() => store.state.defLang),
    }
  },
  data() {
    return {
      languageParameter: this.$route.params.languageParameter,
      textList: [],
      textValue: null,
      dialogVisible: null,
      dialogTextList: null,
      selectedText: null,
      languageValue: null,
      languageValueList: null,
      loading: true,
      filters: null,
    }
  },
  created() {
    this.initFilter()
  },
  mounted() {
    setTimeout(() => {
      this.getTextList()
    }, 250)
    this.getLanguagesValueList()
    this.languageValue = this.languageParameter
  },
  computed: {

  },
  watch: {
    languageValue: function() {
      this.loading = true
      this.$router.replace('/texts/' + this.languageValue)
    },
    '$route'(to) {
        setTimeout(() => {
          this.languageParameter = to.params.languageParameter
          if (typeof this.languageParameter !== 'undefined') {
            this.loading = true
            this.getTextList()
          }
        }, 100)
    },
  },
  methods: {
    getTextList() {
      axios
          .get( 'administration/language/' + this.languageParameter + '/text/list',  {
            headers: {
              Authorization: this.token
            }
          })
          .then( res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0){
              this.textList = res.data.retObj
              this.loading = false
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error => {
            console.log("There was an error loading text list!")
            console.log(error)
          })
    },
    openDialog(text) {
      axios
          .get( 'administration/language/text/' + text.id + '/list',  {
            headers: {
              Authorization: this.token
            }
          })
          .then( res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0){
              this.dialogTextList = res.data.retObj.texts
              this.dialogVisible = true
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error => {
            console.log("There was an error loading text ID list!")
            console.log(error)
          })
    },
    saveText() {
      this.loading = true
      axios
          .post('/administration/language/' + this.languageParameter + '/text/' + this.dialogTextList[0].id + '/saveTexts', {
            'texts': this.dialogTextList
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
              this.dialogVisible = false
              this.textList = res.data.retObj
              this.loading = false
            } else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log(error);
          })
    },
    getLanguagesValueList() {
      axios
          .get( '/app/webObjects/dropdown/ALL_LANGUAGES/KEY-VALUE/false/get', {
            headers: {
              Authorization: this.token
            }})
          .then( response => {
            if(response.status === 200 && response.data.rmsList[0].retCode === 0){
              this.languageValueList = response.data.retObj.dropDownList;
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
            }
          })
          .catch(error => {
            console.log("There was an error loading language list!");
            console.log(error);
          })
    },
      initFilter() {
          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          }
      },
      clearFilter() {
          this.initFilter()
      }
  }
};
</script>

<style scoped>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

</style>